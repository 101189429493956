'use client'

import isEmail from 'is-email'
import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useCallback, useState } from 'react'

import DB from '@/data/db.json'
import * as C from '@/components'
import { type Job } from '@/types'
import { submitNewsletterEmail } from '@/api'

const { NODE_ENV } = process.env
const { jobs } = DB

const HomePage = (): JSX.Element => {
  const [newsletterEmail, setNewsletterEmail] = useState<string>('')
  const [newsletterEmailError, setNewsletterEmailError] = useState<string>('')
  const [isNewsletterEmailSubmitted, setIsNewsletterEmailSubmitted] =
    useState<boolean>(false)
  const [isNewsletterEmailSubmitting, setIsNewsletterEmailSubmitting] =
    useState<boolean>(false)

  const onSubmitNewsletterEmail = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      console.log('1')
      if (isNewsletterEmailSubmitting) {
        console.warn('Already submitting newsletter email')
        return
      } else if (isNewsletterEmailSubmitted) {
        console.warn('Already submitted newsletter email')
        return
      }

      if (_isEmpty(newsletterEmail)) {
        setNewsletterEmailError('Email is required')
        setNewsletterEmail('')
      } else if (!isEmail(newsletterEmail)) {
        setNewsletterEmailError('Email is invalid')
        setNewsletterEmail('')
      } else {
        setIsNewsletterEmailSubmitting(true)

        submitNewsletterEmail(newsletterEmail)
          .then(() => {
            console.log(`submitted newsletter email: ${newsletterEmail}`)

            setIsNewsletterEmailSubmitted(true)
          })
          .catch((err: Error) => {
            if (NODE_ENV === 'development') {
              console.error(err.stack)
            } else {
              console.error(err.message)
            }

            // TODO: Refactor this and provide a fixed error message
            // i.e.email exists
            setNewsletterEmailError(err.message)
          })
          .finally(() => {
            setIsNewsletterEmailSubmitting(false)
            setNewsletterEmail('')
          })
      }

      e.preventDefault()
      e.stopPropagation()

      return false
    },
    [newsletterEmail, isNewsletterEmailSubmitting, isNewsletterEmailSubmitted]
  )

  useEffect(() => {
    console.log(newsletterEmailError)
  }, [newsletterEmailError])

  return (
    <div>
      <C.Hero
        jobs={jobs as Job[]}
        newsletterEmail={newsletterEmail}
        setNewsletterEmail={setNewsletterEmail}
        newsletterEmailError={newsletterEmailError}
        onSubmitNewsletterEmail={onSubmitNewsletterEmail}
        isNewsletterEmailSubmitted={isNewsletterEmailSubmitted}
        isNewsletterEmailSubmitting={isNewsletterEmailSubmitting}
      />

      <C.JobListFilterable
        jobs={jobs as Job[]}
        newsletterEmail={newsletterEmail}
        setNewsletterEmail={setNewsletterEmail}
        newsletterEmailError={newsletterEmailError}
        onSubmitNewsletterEmail={onSubmitNewsletterEmail}
        isNewsletterEmailSubmitted={isNewsletterEmailSubmitted}
        isNewsletterEmailSubmitting={isNewsletterEmailSubmitting}
      />
    </div>
  )
}

export default HomePage
